import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { Wrapper } from '../Wrapper'
import { getChar, getRegularExpression } from 'SRC/utils'
import { Hint } from '../Hint'
import { IMaskInput } from 'react-imask'

export class TextInput extends React.Component {
  renderTextInput = (
    {
      input,
      meta: {active, error, touched},
      id, type, disabled, onMouseEnter, placeholder, className, wrapperClass, isFullWidth, label, isRequired, onKeyPress,
      hint, measure, isHtmlLabel, needColon
    }
  ) => {
    const {onChange, ...restInput} = input
    return (
      <Wrapper isError={error && !active && touched} isFullWidth={isFullWidth} className={wrapperClass}>
        {isHtmlLabel
          ? <label dangerouslySetInnerHTML={{__html: label}} />
          : <label htmlFor={id}>
            {needColon ? `${label}:` : label} {isRequired ? <span className='required'>*</span> : null}
          </label>
        }
        {hint ? <Hint hint={this.props.hint} /> : null}
        <input
          {...restInput}
          id={id}
          key={id}
          type={type}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          placeholder={placeholder}
          className={className}
          onKeyPress={onKeyPress}
          onChange={onChange}
        />
        {measure ? <span className='input-addon'>{this.props.measure}</span> : null}
      </Wrapper>
    )
  }

  renderMaskedInput = (
    {
      input,
      meta: {active, error, touched},
      id, type, disabled, onMouseEnter, placeholder, className, wrapperClass, isFullWidth, label, isRequired, onKeyPress,
      hint, measure, mask, isHtmlLabel
    }
  ) => {
    const {onChange, ...restInput} = input
    return (
      <Wrapper isError={error && !active && touched} isFullWidth={isFullWidth} className={wrapperClass}>
        {isHtmlLabel
          ? <label dangerouslySetInnerHTML={{__html: label}} />
          : <label htmlFor={id}>{label}: {isRequired ? <span className='required'>*</span> : null}</label>
        }
        {hint ? <Hint hint={this.props.hint} /> : null}
        <IMaskInput
          {...mask}
          {...restInput}
          id={id}
          key={id}
          type={type}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          placeholder={placeholder}
          className={className}
          onKeyPress={onKeyPress}
          onAccept={this.onAccept}
          onChange={onChange}
        />
        {measure ? <span className='input-addon'>{this.props.measure}</span> : null}
      </Wrapper>
    )
  }

  onKeyPress = e => {
    const chr = getChar(e)
    if (e.ctrlKey || e.altKey || chr == null) return
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const isValid = regexp ? regexp.test(chr) : true
      console.log('is valid', isValid)
      if (!isValid) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  onAccept = value => {
    if (this.props.onAccept) {
      this.props.onAccept(value, {...this.props})
    }
  }

  onChange = e => {
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const text = e.target.value
      const chars = text.split('')
      for (const char of chars) {
        const isValid = regexp ? regexp.test(char) : true
        if (!isValid) {
          e.preventDefault()
        }
      }
    }
    if (this.props.onChange) {
      this.props.onChange(e, {...this.props})
    }
  }

  normalize = value => value && this.props.mask ? `${value}`.replace(/ /g, '') : value

  render () {
    const {
      name, mask, id, type, isBlocked, onMouseEnter, onFocus, onBlur, placeholder, className, label, isRequired,
      isFullWidth, wrapperClass, hint, measure, validate, isHtmlLabel, needColon
    } = this.props
    return <Field
      name={name}
      onChange={mask ? null : this.onChange}
      id={id}
      component={mask ? this.renderMaskedInput : this.renderTextInput}
      type={type}
      disabled={isBlocked}
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      className={className}
      label={label}
      isRequired={isRequired}
      isFullWidth={isFullWidth}
      wrapperClass={wrapperClass}
      onKeyPress={this.onKeyPress}
      hint={hint}
      measure={measure}
      mask={mask || {}}
      normalize={this.normalize}
      validate={validate}
      isHtmlLabel={isHtmlLabel}
      needColon={needColon}
    />
  }
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseEnter: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  allowedCharacters: PropTypes.array,
  hint: PropTypes.string,
  measure: PropTypes.string,
  mask: PropTypes.shape({
    mask: PropTypes.any.isRequired,
    maskChar: PropTypes.any,
    formatChars: PropTypes.object
  }),
  onChange: PropTypes.func,
  onAccept: PropTypes.func,
  validate: PropTypes.any,
  isHtmlLabel: PropTypes.bool.isRequired,
  needColon: PropTypes.bool.isRequired
}

TextInput.defaultProps = {
  isRequired: false,
  isBlocked: false,
  isFullWidth: false,
  label: '',
  type: 'text',
  allowedCharacters: [],
  isHtmlLabel: false,
  needColon: true
}
