import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'

export class Hidden extends React.PureComponent {
  renderField = ({input}) =>
    <input {...input} type='hidden' value={this.props.value} />

  render () {
    const {name} = this.props
    return <Field component={this.renderField} type='hidden' name={name} />
  }
}

Hidden.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}
