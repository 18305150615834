import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { Hint } from '../Hint'

export class Checkbox extends React.PureComponent {
  renderCheckbox = ({input, meta: {error}, hint, id, label}) =>
    <div className='dodatna-oprema-checkbox'>
      <input
        {...input}
        id={id}
        checked={input.value}
        type='checkbox'
        value={id}
        onInput={this.props.onInput}
        data-id={id}
      />
      <label htmlFor={id}>{label}</label>
      {
        hint ? <Hint hint={hint} /> : null
      }
    </div>

  render () {
    return <Field
      name={this.props.name}
      id={this.props.id}
      component={this.renderCheckbox}
      label={this.props.label}
      hint={this.props.hint}
    />
  }
}

Checkbox.propTypes = {
  hint: PropTypes.string,
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onInput: PropTypes.func
}
