import PropTypes from 'prop-types'
import React from 'react'
import { Wrapper } from '../Wrapper'
import { change, Field, getFormInitialValues } from 'redux-form'
import { connect } from 'react-redux'

export class Apps extends React.Component {
  static viber = 'viber'
  static whatsapp = 'whatsapp'

  constructor (props) {
    super(props)

    this.labelRef = React.createRef()
    this.optionsRef = React.createRef()

    this.state = {checkedOptions: this.getCheckedOptions(), isOptionsVisible: false}
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.toggleOptionsVisibility)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.toggleOptionsVisibility)
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.initViberValue !== prevProps.initViberValue ||
      this.props.initWhatsappValue !== prevProps.initWhatsappValue
    ) {
      this.setCheckedOptions()
    }
  }

  getCheckedOptions = () => {
    const checkedOptions = []
    const options = this.getOptions()
    const viber = options.filter(item => item.name === this.props.viberFieldName)
    const whatsapp = options.filter(item => item.name === this.props.whatsappFieldName)
    if (this.props.initViberValue && viber.length) {
      checkedOptions.push(viber[0].label)
    }
    if (this.props.initWhatsappValue && whatsapp.length) {
      checkedOptions.push(whatsapp[0].label)
    }
    return checkedOptions
  }

  setCheckedOptions = () => {
    this.setState({...this.state, checkedOptions: this.getCheckedOptions()})
  }

  toggleOptionsVisibility = (e) => {
    if (!this.props.isBlocked) {
      if (this.labelRef.current.contains(e.target)) {
        this.setState(prevState => ({...prevState, isOptionsVisible: !prevState.isOptionsVisible}))
      } else {
        const isVisible = this.optionsRef.current.contains(e.target)
        this.setState({...this.state, isOptionsVisible: isVisible})
      }
    }
  }

  getOptions = () => [
    {name: this.props.viberFieldName, label: 'Viber'},
    {name: this.props.whatsappFieldName, label: 'Whatsapp'}
  ]

  getLabelText = () => {
    let text
    if (Array.isArray(this.state.checkedOptions) && this.state.checkedOptions.length) {
      if (this.state.checkedOptions.length > 2) {
        text = `Odabrano: ${this.state.checkedOptions.length}`
      } else {
        text = this.state.checkedOptions.join(', ')
      }
    } else {
      text = 'Izaberi'
    }
    return text
  }

  onOptionClick = async (e) => {
    if (!this.props.isBlocked) {
      const tagName = e.target.tagName
      let inputNode
      if (tagName === 'LI') {
        inputNode = e.target.firstChild
        if (inputNode.checked) {
          inputNode.checked = false
          await this.removeCheckedOptionFromState(inputNode.dataset.label)
        } else {
          inputNode.checked = true
          await this.addCheckedOptionToState(inputNode.dataset.label)
        }
      } else {
        inputNode = e.target
        if (inputNode.checked) {
          await this.addCheckedOptionToState(inputNode.dataset.label)
        } else {
          await this.removeCheckedOptionFromState(inputNode.dataset.label)
        }
      }
      this.setValue()
    }
  }

  addCheckedOptionToState = async (value) => {
    await this.setState((prevState) => ({...prevState, checkedOptions: [...prevState.checkedOptions, value]}))
  }

  removeCheckedOptionFromState = async (value) => {
    await this.setState((prevState) => ({
      ...prevState,
      checkedOptions: prevState.checkedOptions.filter(option => option !== value)
    }))
  }

  setValue = () => {
    const options = this.getOptions()
    const viber = options.filter(item => item.name === this.props.viberFieldName)
    const whatsapp = options.filter(item => item.name === this.props.whatsappFieldName)
    if (viber.length) {
      this.props.change(this.props.formName, this.props.viberFieldName, this.state.checkedOptions.includes(viber[0].label))
    }
    if (whatsapp.length) {
      this.props.change(this.props.formName, this.props.whatsappFieldName, this.state.checkedOptions.includes(whatsapp[0].label))
    }
  }

  renderCheckbox = ({input, disabled, label}) =>
    <input
      {...input}
      type='checkbox'
      disabled={disabled}
      defaultChecked={input.value}
      data-label={label}
    />

  render () {
    return (
      <Wrapper>
        <label className='select-field' htmlFor={this.props.id}>{this.props.label}</label>
        <div
          className='select-with-checkbox'
          ref={this.optionsRef}
          onFocus={this.props.onFocus}
          onMouseEnter={this.props.onMouseEnter}
        >
          <label className='select-field' ref={this.labelRef}>{this.getLabelText()}</label>
          {
            this.state.isOptionsVisible
              ? <div className='select-dropdown'>
                <ul>
                  {
                    this.getOptions().map(option =>
                      <li key={option.name} onClick={this.onOptionClick}>
                        <Field
                          component={this.renderCheckbox}
                          name={option.name}
                          disabled={this.props.isBlocked}
                          label={option.label}
                        />
                        {option.label}
                      </li>
                    )
                  }
                </ul>
              </div>
              : null
          }
        </div>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {initViberValue: false, initWhatsappValue: false}
  if (ownProps.formName) {
    const initValues = getFormInitialValues(ownProps.formName)(state)
    if (initValues && Array.isArray(initValues.phones) && initValues.phones.length) {
      const phone = initValues.phones[ownProps.index]
      if (phone) {
        if (phone.whatsapp) {
          props.initWhatsappValue = true
        }
        if (phone.viber) {
          props.initViberValue = true
        }
      }
    }
  }
  return props
}

Apps.propTypes = {
  id: PropTypes.string.isRequired,
  viberFieldName: PropTypes.string.isRequired,
  whatsappFieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  initViberValue: PropTypes.bool.isRequired,
  initWhatsappValue: PropTypes.bool.isRequired
}

Apps.defaultProps = {
  isBlocked: false,
  viberFieldName: Apps.viber,
  whatsappFieldName: Apps.whatsapp,
  initViberValue: false,
  initWhatsappValue: false
}

export default connect(mapStateToProps, {change})(Apps)
