import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { Wrapper } from '../Wrapper'

export class MultipleSelect extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isOptionsVisible: false,
      checkedOptions: []
    }

    this.labelRef = React.createRef()
    this.optionsRef = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.toggleOptionsVisibility)
    if (this.props.checked) this.setState({ checkedOptions: this.props.checked.map(opt => opt.toString()) })
  }

  static getDerivedStateFromProps (props, state) {
    if (props.resetOptions) {
      return {checkedOptions: []}
    }
    return null
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.toggleOptionsVisibility)
  }

  toggleOptionsVisibility = (e) => {
    if (this.labelRef.current.contains(e.target)) {
      this.setState(prevState => ({
        ...prevState,
        isOptionsVisible: !prevState.isOptionsVisible
      }))
    } else {
      const isVisible = this.optionsRef.current.contains(e.target)
      if (this.state.isOptionsVisible && !isVisible && this.props.onOptionsClosed) {
        this.props.onOptionsClosed(this.state.checkedOptions, this.props)
      }

      this.setState(prevState => ({
        ...prevState,
        isOptionsVisible: isVisible
      }))
    }
  }

  getLabelText = () => {
    let text
    if (Array.isArray(this.state.checkedOptions) && this.state.checkedOptions.length) {
      text = `Odabrano: ${this.state.checkedOptions.length}`
    } else {
      text = 'Izaberi'
    }
    return text
  }

  onOptionClick = async e => {
    const tagName = e.target.tagName
    let inputNode
    if (tagName === 'LI') {
      inputNode = e.target.firstChild
      if (inputNode.checked) {
        inputNode.checked = false
        await this.removeCheckedOptionFromState(inputNode.value)
      } else {
        inputNode.checked = true
        await this.addCheckedOptionToState(inputNode.value)
      }
    } else {
      inputNode = e.target
      if (inputNode.checked) {
        await this.addCheckedOptionToState(inputNode.value)
      } else {
        await this.removeCheckedOptionFromState(inputNode.value)
      }
    }
    this.props.onChange(this.state.checkedOptions, this.props)
  }

  addCheckedOptionToState = async value => {
    await this.setState((prevState) => ({
      ...prevState,
      checkedOptions: [...prevState.checkedOptions, value]
    }))
  }

  removeCheckedOptionFromState = async value => {
    await this.setState((prevState) => ({
      ...prevState,
      checkedOptions: prevState.checkedOptions.filter(option => option !== value)
    }))
  }

  renderMultipleSelect = ({ input, meta: {active, error, touched}, id, isOptionsVisible, label, isRequired,
    disabled, className, options, checkedOptions, needColon}) => (
    // eslint-disable-next-line react/jsx-indent
    <Wrapper isError={error && touched} className={className}>
      <label htmlFor={id}>
        {label}{needColon ? `:` : null} {isRequired ? <span className='required'>*</span> : null}
      </label>
      <div className='select-with-checkbox' ref={this.optionsRef}>
        <label ref={this.labelRef} className={(error && touched) ? 'no-border' : ''}>
          {this.getLabelText()}
        </label>
        {isOptionsVisible && !disabled
          ? <div className='ads-filter__select-dropdown'>
            <ul>
              {options.map(option => option.isGrouped
                ? <React.Fragment key={option.id}>
                  <h3 className='ads-filter__groupName'>{option.label}</h3>
                  {option.options.map(item =>
                    <li key={item.id ? item.id : item} onClick={!item.isDisabled ? this.onOptionClick : null}>
                      <input
                        {...input}
                        disabled={item.isCheckboxDisabled}
                        type='checkbox'
                        value={item.value}
                        defaultChecked={Array.isArray(checkedOptions) && checkedOptions.includes(item.value.toString())}
                      />
                      {item.label}
                    </li>
                  )}
                </React.Fragment>
                : <li key={option.id ? option.id : option} onClick={!option.isDisabled ? this.onOptionClick : null}>
                  <input
                    {...input}
                    disabled={option.isCheckboxDisabled}
                    type='checkbox'
                    value={option.value}
                    defaultChecked={Array.isArray(checkedOptions) && checkedOptions.includes(option.value.toString())}
                  />
                  {option.label}
                </li>)
              }
            </ul>
          </div>
          : null}
      </div>
    </Wrapper>)

  render () {
    return <Field
      id={this.props.id}
      name={this.props.name}
      component={this.renderMultipleSelect}
      isOptionsVisible={this.state.isOptionsVisible}
      checkedOptions={this.state.checkedOptions}
      onChange={this.onChange}
      onFocus={this.props.onFocus}
      onBlur={this.props.onBlur}
      disabled={this.props.disabled || this.props.isBlocked}
      onMouseEnter={this.props.onMouseEnter}
      label={this.props.label}
      className={this.props.className}
      isRequired={this.props.isRequired}
      options={this.props.options}
      labelDefault={this.props.labelDefault}
      selectClassName={this.props.selectClassName}
      validate={this.props.validate}
      needColon={this.props.needColon}
      normalize={this.props.normalize} />
  }
}

MultipleSelect.propTypes = {
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired
    }))
  })).isRequired,
  onOptionsClosed: PropTypes.func,
  checked: PropTypes.array,
  label: PropTypes.string,
  labelDefault: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  isBlocked: PropTypes.bool.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseEnter: PropTypes.func,
  selectClassName: PropTypes.string,
  validate: PropTypes.any,
  needColon: PropTypes.bool.isRequired,
  normalize: PropTypes.func,
  resetOptions: PropTypes.bool
}

MultipleSelect.defaultProps = {
  disabled: false,
  options: [],
  isRequired: false,
  isError: false,
  isBlocked: false,
  labelDefault: 'Izaberi',
  needColon: true,
  checked: [],
  resetOptions: false
}
