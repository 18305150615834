import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Field, change } from 'redux-form'
import parseNumber, { AsYouType } from 'libphonenumber-js'
import { Wrapper } from '../Wrapper'
import { IMaskInput } from 'react-imask'
import { phoneMask, phoneWithDialMask } from 'Core/masks'

const PhoneInputNoDial = ({
  input,
  meta: { error, form },
  id, label, isRequired, onMouseEnter, disabled, wrapperClass, inputClass, change
}) => {
  const inputRef = React.useRef(null)

  return (
    <Wrapper isError={Boolean(error)} className={wrapperClass}>
      <label htmlFor={id}>
        {label}: {isRequired ? <span className='required'>*</span> : null}
      </label>
      <input
        {...input}
        ref={inputRef}
        type='text'
        className={inputClass}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        onChange={e => {
          const value = e.target.value
          let phoneNumber = new AsYouType().input(`+${value.replace('+', '')}`)
          const parsedPhoneNumber = parseNumber(phoneNumber)
          if (parsedPhoneNumber && parsedPhoneNumber.countryCallingCode && parsedPhoneNumber.nationalNumber) {
            const dial = parsedPhoneNumber.countryCallingCode
            let number = parsedPhoneNumber.nationalNumber
            if (number.charAt(0) === '0') number = number.substring(1)
            phoneNumber = new AsYouType().input(`+${dial}${number}`)
          }
          if (phoneNumber === '+') phoneNumber = ''

          if (inputRef.current) {
            inputRef.current.value = phoneNumber
            change(form, input.name, phoneNumber)
          }
        }}
      />
    </Wrapper>
  )
}

class PhoneInput extends React.PureComponent {
  renderPhoneInputWithDial = (
    {
      input,
      meta: { active, error, touched },
      id, dial, label, isRequired, onMouseEnter, disabled, wrapperClass, inputClass, dialClass
    }
  ) =>
    <Wrapper isError={error && !active && touched} className={wrapperClass}>
      <label htmlFor={id}>
        {label}: {isRequired ? <span className='required'>*</span> : null}
      </label>
      <div className='form-telefon__container'>
        <span className={dialClass}>{`+${dial}`}</span>
        <IMaskInput
          {...phoneMask}
          {...input}
          className={inputClass}
          type='text'
          onMouseEnter={onMouseEnter}
          disabled={disabled}
        />
      </div>
    </Wrapper>

  renderPhoneInputWithSelectDial = (
    {
      input,
      meta: { error },
      id, label, dial, selectedCountry, isRequired, onMouseEnter, onCountryChange, disabled, wrapperClass, inputClass
    }
  ) => {
    const countries = this.prepareCountries()

    return <Wrapper isError={Boolean(error)} className={wrapperClass}>
      <label htmlFor={id}>
        {label}: {isRequired ? <span className='required'>*</span> : null}
      </label>
      <div className='select-wrap' style={{ border: '1px solid #dcdae5' }}>
        <label>{dial ? `+${dial}` : ''}</label>

        <select className='dial-select' name='existingUser.country' onChange={onCountryChange}>
          {countries && countries.length ? countries.map(country => {
            if (country.isGrouped) {
              return <optgroup id={country.id} label={country.label}>
                {country.options && country.options.length
                  ? country.options.map(childCountry =>
                    <option id={childCountry.id} value={childCountry.dial}>{childCountry.label}</option>
                  ) : null}
              </optgroup>
            } else {
              return <option key={country.id} value={country.dial}>{country.label}</option>
            }
          }) : null}
        </select>
      </div>

      <IMaskInput
        {...phoneMask}
        {...input}
        type='text'
        className={inputClass}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
      />
    </Wrapper>
  }

  prepareCountries = () => this.props.countries.filter(country => !country.parent).map(country => {
    const isGrouped = Boolean(this.props.countries.filter(item => item.parent === country.id).length)
    return {
      id: country.id,
      label: country.label,
      value: country.id,
      dial: country.dial,
      isGrouped,
      options: isGrouped ? this.props.countries.filter(item => item.parent === country.id).map(childCountry => ({
        id: childCountry.id,
        value: childCountry.id,
        label: childCountry.label,
        dial: childCountry.dial
      })) : null
    }
  })

  format = value => this.props.needDial && !this.props.selectDial
    ? value && value.replace(this.props.dial, '')
    : value

  normalize = value => this.props.needDial && !this.props.selectDial
    ? `${this.props.dial}` + `${value}`.replace(/ /g, '')
    : `${value}`.replace(/ /g, '')

  renderComponent = () => {
    const { needDial, selectDial } = this.props

    if (selectDial) {
      return this.renderPhoneInputWithSelectDial
    }

    if (needDial) {
      return this.renderPhoneInputWithDial
    } else {
      return this.renderPhoneInputWithoutDial
    }
  }

  render() {
    return <Field
      name={this.props.name}
      component={PhoneInputNoDial}
      id={this.props.id}
      dial={this.props.dial}
      selectedCountry={this.props.selectedCountry}
      label={this.props.label}
      isRequired={this.props.isRequired}
      onMouseEnter={this.props.onMouseEnter}
      onCountryChange={this.props.onCountryChange}
      onFocus={this.props.onFocus}
      onChange={this.props.onChange}
      change={this.props.change}
      disabled={this.props.isBlocked}
      wrapperClass={this.props.wrapperClass}
      inputClass={this.props.inputClass}
      dialClass={this.props.dialClass}
      validate={this.props.validate}
    />
  }
}

PhoneInput.propTypes = {
  dial: PropTypes.number,
  selectedCountry: PropTypes.number,
  countries: PropTypes.array,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  needDial: PropTypes.bool.isRequired,
  selectDial: PropTypes.bool.isRequired,
  wrapperClass: PropTypes.string,
  inputClass: PropTypes.string,
  dialClass: PropTypes.string,
  validate: PropTypes.any,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onCountryChange: PropTypes.func,
  change: PropTypes.func,
  onChange: PropTypes.func
}

PhoneInput.defaultProps = {
  isRequired: false,
  label: '',
  isBlocked: false,
  needDial: true,
  selectDial: false,
  inputClass: 'form-group__phone',
  dialClass: 'form-group__mask'
}

export default connect(null, { change })(PhoneInput)
