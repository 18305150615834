import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { Wrapper } from '../Wrapper'

export class Radio extends React.PureComponent {
  renderRadio = (
    {
      input,
      meta: {active, error, touched},
      disabled, options, onMouseEnter, onFocus, wrapperClassName
    }
  ) => options.map(
    item =>
      <Wrapper isError={error && !active && touched} key={item.value} className={wrapperClassName}>
        <label>
          <Field
            {...input}
            component='input'
            type='radio'
            value={item.value}
            className={item.className}
            disabled={disabled}
            onFocus={onFocus}
            onMouseEnter={onMouseEnter}
            checked={item.value === input.value}
          />
          <p>{item.label}</p>
        </label>
      </Wrapper>
  )

  render () {
    const {name, isBlocked, options, onMouseEnter, onFocus, wrapperClassName} = this.props
    return <Field
      component={this.renderRadio}
      name={name}
      disabled={isBlocked}
      options={options}
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      wrapperClassName={wrapperClassName}
    />
  }
}

Radio.propTypes = {
  isBlocked: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    className: PropTypes.string
  })),
  name: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func,
  wrapperClassName: PropTypes.string
}

Radio.defaultProps = {
  isBlocked: false
}
