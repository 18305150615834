import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { getChar, getRegularExpression } from 'SRC/utils'

export class Textarea extends React.PureComponent {
  renderTextarea = (
    {
      input,
      meta: {active, error, touched},
      id, disabled, onMouseEnter, label, maxLength, onKeyPress, onPaste, wrapperClass, isRequired
    }
  ) =>
    <div className={`${wrapperClass} ${!active && error && touched ? `form-group_error` : ``}`}>
      <label htmlFor={id}>{label}: {isRequired ? <span className='required'>*</span> : null}</label>
      <textarea
        {...input}
        id={id}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        maxLength={Number(maxLength) > 0 ? maxLength : null}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
      />
    </div>

  onKeyPress = e => {
    const chr = getChar(e)

    if (e.ctrlKey || e.altKey || chr == null) return
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const isValid = regexp ? regexp.test(chr) : true
      if (!isValid) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  onPaste = e => {
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const text = e.clipboardData.getData('Text')
      const chars = text.split('')
      for (const char of chars) {
        const isValid = regexp ? regexp.test(char) : true
        if (!isValid) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    }
  }

  onChange = e => {
    console.log('onchange', e)
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const text = e.target.value
      const chars = text.split('')
      for (const char of chars) {
        const isValid = regexp ? regexp.test(char) : true
        if (!isValid) {
          e.preventDefault()
        }
      }
    }
  }

  render () {
    return <Field
      name={this.props.name}
      id={this.props.id}
      component={this.renderTextarea}
      disabled={this.props.isBlocked}
      onMouseEnter={this.props.onMouseEnter}
      onFocus={this.props.onFocus}
      label={this.props.label}
      maxLength={this.props.maxLength}
      onKeyPress={this.onKeyPress}
      onPaste={this.onPaste}
      onChange={this.onChange}
      wrapperClass={this.props.wrapperClass}
      isRequired={this.props.isRequired}
      validate={this.props.validate}
    />
  }
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  maxLength: PropTypes.number,
  allowedCharacters: PropTypes.array,
  wrapperClass: PropTypes.string,
  isRequired: PropTypes.bool,
  validate: PropTypes.any
}

Textarea.defaultProps = {
  isBlocked: false,
  maxLength: 0,
  allowedCharacters: [],
  wrapperClass: 'info-polje info-ime',
  isRequired: false
}
